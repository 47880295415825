import Rails from '@rails/ujs'
import Flickity from 'flickity'

$(document).on('turbolinks:before-cache', function() {
  if($('.inbox-item div.carousel').length > 0) {
    var carouselElems = document.querySelectorAll('.carousel');

    for ( var i=0, len = carouselElems.length; i < len; i++ ) {
      var carouselElem = carouselElems[i];
      var flckty = new Flickity(carouselElem);
      flckty.destroy();
    }
  }
});

$(document).on('ready page:load turbolinks:load', function() {
  $(document).delegate('#inbox_items--filter', 'ajax:beforeSend', function(event) { 
    $('.inbox_items--activity-indicator').removeClass('hidden');    
  });

  $(document).delegate('#inbox_items--filter', 'ajax:complete', function(event) { 
    $('.inbox_items--activity-indicator').addClass('hidden');    
  });

  $('#item-sort-recent a').off('click').on('click', function(event) {
    $('#inbox_item_sort').val('')
    event.preventDefault();    
    Rails.fire($('#inbox_items--filter')[0], 'submit');
  });

  $('#item-sort-like a').off('click').on('click', function(event) {
    $('#inbox_item_sort').val('like')
    event.preventDefault();    
    Rails.fire($('#inbox_items--filter')[0], 'submit');
  });
 
  $('#inbox_items--filter input[type=text]').off('input').on('input', $.Utils.debounce(function (event) {  
    Rails.fire($('#inbox_items--filter')[0], 'submit');
  }, 300));
});

$(document).on('ready page:load turbolinks:load inbox:reload', function() {
  // Reload tooltips for inbox:load
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  if($('.inbox-item div.carousel').length > 0) {
    var carouselElems = document.querySelectorAll('.inbox-item div.carousel');

    for ( var i=0, len = carouselElems.length; i < len; i++ ) {
      var carouselElem = carouselElems[i];
      var flktyData = carouselElem.getAttribute('data-flickity');
      var flktyOptions = null;
      if (flktyData) {
        flktyOptions = JSON.parse(flktyData);
      }
      var flkty = new Flickity(carouselElem, flktyOptions);
    }    
  }

  function repliableComment(comment) {
    if(FB === undefined || FB.getAccessToken() == null) {
      return;
    }

    // Find the form for this comment's inbox item
    var id = comment.data('inbox-item-id');
    var form_id = '#inbox-item-' + id + ' .inbox-item__comment-form'
    var form_errors_id = '#inbox-item-' + id + ' .inbox-item__comment-form-errors'

    // Reset the error display
    $(form_errors_id).html('');
    $(form_errors_id).hide();

    // Move the form under the selected comment
    $('#comment-' + comment.data('comment-uid')).append($(form_id));
    $(form_id + ' textarea').focus();
    $(form_id).show();  
    
    // Update the parent comment hidden field to reference this comment
    var parent_comment_id = '#inbox-item-' + id + '-parent-comment-uid'
    $(parent_comment_id).val(comment.data('comment-uid'));

    var text_id = '#inbox-item-' + id + '-comment-text'
    if(comment.data('is-reply') && comment.data('username')) {
      $(text_id).val("@" + comment.data('username') + " ");      
    }
    else {
      $(text_id).val("");        
    }

    // Set the user token on the form
    var user_token = FB.getAccessToken();
    var user_token_id = '#inbox-item-' + id + '-user-token'
    $(user_token_id).val(user_token);
  }

  $('.inbox-item__comment--repliable').on('click', function (event) {
    // Prevent link from following its href
    event.preventDefault();
    
    repliableComment($(this));
  });

  descriptionReadmore($('.inbox-item .notes-body'));
});

function descriptionReadmore(element){
  new Readmore($(element).selector, {
      speed: 5,
      collapsedHeight: 58,
      moreLink: '<a style="font-size:10px;margin-bottom:8px;margin-top:-8px;" href="#">[&hellip;]</a>',
      lessLink: '<a></a>'
    });
}